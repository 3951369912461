/* import bootstrap to set changes */
@import "./_typography";
@import "./variable";
@import "~react-bootstrap-typeahead/css/Typeahead.css";
@import "~bootstrap/scss/bootstrap";

body {
  .flex-center {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .carousel-indicators {
    bottom: -100px;
    li {
      width: 10px;
      height: 0px;
      background-color: #fff;
      border-top: 5px solid white;
      border-bottom: 5px solid white;
      border-radius: 100%;
    }
  }
  .carousel-inner {
    margin-top: -7rem;
  }

  .custom-invalid-feedback {
    width: 100%;
    margin-top: -16px;
    font-size: 0.875rem;
    margin-bottom: 14px;
  }
  .calender-icon {
    .form-control[readonly] {
      background: none;
    }
  }
  .alert-btn {
    .badge {
      top: -8px;
      left: -12px;
      font-size: 12px;
      border-radius: 50%;
    }
    &:hover {
      background-color: rgba(154, 168, 193, 0.1);
    }
  }
  .alert-btn-active {
    background-color: #f0f6ff;
  }
  .calender-icon {
    .form-control[readonly] {
      background: none;
    }
  }
  @media (min-width: 1200px) {
    .container {
      max-width: 1000px;
    }
    .email-button {
      position: relative;
      top: 36px;
    }
  }
  .global-table td {
    vertical-align: text-bottom;
  }
  .model-button-primary {
    &:disabled {
      background-color: $info;
      border-color: $info;
    }
  }
  .red {
    border-left: 2px solid $danger;
    background-color: #ffe8e8 !important;
    &:active {
      background-color: #fdc1c1 !important;
    }
    &:hover {
      background-color: #ffd5d5 !important;
    }
  }
  .internal-card {
    border-left: 2px solid $primary;
    &:hover {
      background-color: #eff5ff !important;
    }
    &:active {
      background-color: #d6e6ff !important;
    }
  }

  /** Breadcrumb css */
  .main-tab {
    .nav-link.active {
      border-bottom: 2px solid $primary !important;
    }
  }
  .nav-tabs .nav-link {
    border: none;
    color: $info;
    &:hover {
      color: $primary;
    }
  }
  /* Modal Css */
  .modal-header {
    background: none;
    .close {
      opacity: 1 !important;
      margin-top: -11px;
      span {
        padding: 0px 9px;
        color: $white;
        &:hover {
          background: #1f4d89;
          opacity: 1 !important;
          border-radius: 6px;
        }
      }
      &:focus {
        outline: none;
      }
    }
  }
  .edit-button {
    &:hover {
      background-color: #d6e6ff;
      color: $secondary;
      border-radius: 2px;
    }
  }
  .delete-button {
    padding: 2px 8px;
    &:hover {
      background-color: #fff5f5;
      border-radius: 6px;
      padding: 2px 8px;
    }
  }
  .delete-icon {
    &:hover {
      background-color: #d6e6ff;
      border-radius: 2px;
    }
  }
  .table-edit-button {
    padding: 2px 8px;
    &:hover {
      border-radius: 6px;
      background-color: #eff5ff;
      padding: 2px 8px;
    }
  }
  .dropdown-toggle:after {
    background-image: url("../assets/images/down-arrow.svg");
    top: 4px;
    right: -6px;
    position: relative;
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 0px;
    background-repeat: no-repeat;
  }
  .btn-outline-warning {
    font-size: 14px;
    &:hover {
      border: solid 1px $warning;
      background-color: #fffae8;
      color: $warning;
    }
  }
  .btn-outline-warning:not(:disabled):not(.disabled):active {
    border: solid 1px $warning;
    background-color: #fffae8;
    color: $warning;
    box-shadow: none;
  }
  .send-button {
    pointer-events: none;
    padding: 5px;
    &:hover {
      background: none;
    }
    &:focus {
      box-shadow: none;
    }
  }
  .eye-icon {
    position: relative;
    margin-top: -42px;
    float: right;
  }
  /** notification design **/
  .Toastify__toast-container {
    .Toastify__toast--dark {
      background-color: $dark;
      border-radius: 4px;
      font-family: $font-family-base;
    }
    .Toastify__close-button {
      opacity: 1;
      margin-top: -2px;
      svg {
        width: 10px;
        margin-top: -12px;
      }
    }
  }
  /** Global css for select dropdown */
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url("../assets/images/select-down.svg");
    background-position: calc(100% - 15px) calc(1em + 2px), 100% 0;
    background-repeat: no-repeat;
  }

  .panel-box {
    background-color: #262626;
    width: 100%;
    color: white;
    text-align: center;
    font-size: 12px;
    z-index: 99;
    padding: 5px 10px;
  }

  .panel-box img {
    width: auto;
    height: 16px;
  }

  .right-border {
    border-right: 1px solid gray;
  }

  .horizontal-border {
    border-bottom: 1px solid gray;
    margin: 0 10px 10px;
  }

  .dygraph-legend {
    position: absolute;
    z-index: 10;
    width: 340px;
  }

  .dygraph-legend span {
    display: inline-block;
    flex-direction: row;
    padding-right: 5px;
  }

  .tooltip-label-wrapper {
    background-color: #343434;
    border-radius: 8px;
  }

  /** Login page design **/
  .login-dark-text {
    color: #002b73 !important;
  }
  .rbt-menu {
    width: 100% !important;
  }
  .privacy-policy-link a {
    &:hover {
      color: $secondary !important;
    }
  }
  .custom-update-btn {
    &:disabled {
      border-color: gray;
      background-color: gray;
    }
  }

  .unit-hover {
    padding: 7px 0px 7px 25px;
    &:hover {
      border-radius: 4px;
      background-color: #f0f6ff;
      padding: 7px 0px 7px 25px;
    }
  }
  .ant-drawer-close {
    padding: 0px;
    position: absolute;
    top: 20px;
    right: 20px;
  }

  .system-item {
    cursor: pointer;
    div {
      background-color: #eb4141 !important;
      width: 2px !important;
      &::before {
        content: "";
        width: 9px;
        height: 9px;
        position: absolute;
        top: -2px;
        left: -3.6px;
        background: #eb4141;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
  .manual-item {
    cursor: pointer;
    div {
      background-color: #3f7cca !important;
      width: 2px !important;
      &::before {
        content: "";
        width: 9px;
        height: 9px;
        position: absolute;
        top: -2px;
        left: -3.6px;
        background: #2c61a5;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
  .hpf-fan {
    width: 50px;
  }
  .fan-rotate {
    animation: rotation 2s infinite linear;
    -webkit-animation: rotation 2s infinite linear;
    margin: 3px -7px;
    width: 66px;
  }
  .icon-pump--rotate {
    [data-name="path-fan"] {
      transform-origin: 50% 50%;
      animation: rotation 2s infinite linear;
      -webkit-animation: rotation 2s infinite linear;
      -moz-animation: rotation 2s infinite linear;
    }
  }
  @keyframes rotation {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(359deg);
    }
  }
  .calender-icon {
    .calender {
      background-image: url("../assets/images/calendar.svg") !important;
      background-repeat: no-repeat !important;
      position: relative;
      background-position: right !important;
      cursor: pointer;
      background-size: 11% 40% !important;
      background-color: none !important;
    }
  }
  /** Mobile screen css */
  .graph-device {
    overflow: scroll;
    white-space: nowrap;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    display: flex;
    img {
      position: relative;
      width: 35px;
      cursor: pointer;
    }
  }
  .mobile-device-image {
    padding: 10px;
    margin-right: 10px;
    border-bottom: none;
    background-color: white;
    display: inline-block;
    margin-bottom: 10px;
  }
  .mobile-device-image-active {
    padding: 10px;
    margin-right: 10px;
    border-bottom: 3px solid #2c61a5;
    background-color: #f0f6ff;
    display: inline-block;
    text-align: center;
    margin-bottom: 10px;
  }
  .vertical-line {
    width: 0px; /* Use only border style */
    height: 75%;
    float: left;
    border: 1px solid #ededed;
  }
  .live-data {
    .ant-drawer-close {
      top: 18px;
    }
  }
  .annotation {
    text-align: center;
    padding: 5px 10px;
    border-radius: 4px;
    border: solid 1px #adcdff;
    margin-right: 10px;
    height: 36px;
    cursor: pointer;
    color: #2c61a5;
    font-size: 14px;
    line-height: 24px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    &:hover {
      background: #f0f6ff;
    }
  }
  .reload-button {
    padding: 0 10px;
    border-radius: 4px;
    border: solid 1px #adcdff;
    margin-right: 10px;
    height: 36px;
    cursor: pointer;
    img {
      position: relative;
      top: 5px;
    }
    &:hover {
      background: #f0f6ff;
    }
  }
  .date-button {
    border-radius: 4px;
    border: solid 1px #adcdff;
    color: #2c61a5;
    cursor: pointer;
    font-size: 14px;
    padding: 5px 10px;
    width: 150px;
    height: 36px;
    line-height: 24px;
    &:hover {
      background: #f0f6ff;
    }
  }
  .vertical-image {
    height: 35px;
  }
  .offlineTank {
    width: 35px;
    text-align: center;
    &:hover{
      cursor: pointer; 
    }
  }
  .hairline-legend {
    padding: 0 !important;
    .untitled {
      &:hover {
        width: auto;
      }
    }
  }
  .manual-item p {
    margin: 10px;
    font-size: 15px;
    border-radius: 4px;
    z-index: 99;
    background-color: #f0f6ff;
    border: 1px solid #3f7cca;
    text-align: center;
    position: absolute;
    top: -54px;
    padding: 8px;
    box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.08);
    color: #2c61a5;
  }
  .system-item p {
    margin: 10px;
    font-size: 15px;
    border-radius: 4px;
    z-index: 99;
    background-color: #ffe8e8;
    border: 1px solid #eb4141;
    text-align: center;
    position: absolute;
    top: -54px;
    padding: 8px;
    box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, 0.08);
    color: #eb4141;
  }
  .ant-drawer-wrapper-body {
    height: 93%;
  }
  .dygraph-axis-label-y {
    font-size: 13px;
  }
  .dygraph-axis-label-y2 {
    width: 60px;
  }
  .carousel-item {
    height: 500px !important;
  }
  .system-title {
    color: $dark;
  }
  .tank-page {
    .ant-divider-horizontal {
      margin: 5px 0;
    }
  }
  .ant-drawer-header {
    margin-top: 2px;
    padding: 16px 24px 7px;
  }
  .hide-annotation {
    border-right: 1px solid #adcdff;
    padding-right: 5px;
    width: fit-content;
  }
  .annotation-tooltip {
    .tooltip-inner {
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
      border-radius: 3px;
      background-color: white;
      color: #262626;
      margin-top: 4px;
      font-size: 10px;
    }
  }

  .annotation-tooltip .arrow::before {
    border-style: none;
  }
  
  .device-tool-tip {
    .tooltip-inner {
      max-width: 250px;
      background-color: #000;
    }
    .evc-tooltip-title {
      padding: 2px 0 1px;
      margin-bottom: 4px;
      background: rgba(217, 217, 217, 0.1);
      border-radius: 4px;
    }

    .tooltip-line-item:not(:last-child):not(:empty) {
      border-bottom:  0.5px solid #ffffff80;
      padding-bottom: 4px;
      margin-bottom: 4px;
    }

    .diffTempValues {
      display: flex;
      justify-content: space-between;
    }
    &.device-tooltip--disabled {
      .arrow::before{
        border-top-color: rgba(255, 233, 233, 0.8);
      }
      .tooltip-inner {
        background-color: rgba(255, 233, 233, 0.8);
        border: 1px solid #FFA6A6;
        color: #FF7373;
    }
  }
  }

  @keyframes anim-glow {
    0% {
      box-shadow: 0 0 rgba(#eb4141, 0.4);
    }
    100% {
      box-shadow: 0 0 10px 8px transparent;
      border-width: 2px;
    }
  }
  .anim-glow {
    div {
      &::before {
        animation: anim-glow 2s ease infinite;
      }
    }
  }
  .header-dropdown {
    background-color: rgba(33, 47, 70, 0.1);
    &:hover {
      background-color: rgba(33, 47, 70, 0.3);
    }
    .header-menu {
      width: 112px;
      display: inherit;
    }
    .login-user-name {
      font-size: 14px;
      line-height: 15px;
      text-align: start;
      text-transform: capitalize;
      margin-top: 1px;
    }
    .user-role {
      margin-left: 1px;
      font-size: 13px;
      text-align: start;
    }
    .show > .header-dropdown.dropdown-toggle {
      background-color: rgba(33, 47, 70, 0.3);
    }
  }
  .tank-red-indicator {
    &::after {
      content: "";
      width: 10px;
      height: 10px;
      position: absolute;
      top: -4px;
      right: 0px;
      background: #eb4141;
      border-radius: 50%;
      display: inline-block;
    }
  }
  .add-tank {
    cursor: pointer;
    img {
      margin-top: -3px;
    }
  }
  .delete {
    width: 30px;
    height: 30px;
    &:hover {
      background: #ffe8e8;
      border-radius: 4px;
      cursor: pointer;
    }
  }
  .legend-tooltip {
    width: 13px;
    height: 13px;
    display: inline-block;
    border-radius: 50%;
    position: relative;
    top: 0px;
    left: 4px;
  }
  .device-scroll {
    white-space: nowrap;
    overflow: scroll;
    flex-shrink: 1;
    flex: 10;
  }
  .collapsed {
    .navbar-toggler-icon {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18.934' height='17.212' viewBox='0 0 18.934 17.212'%3E%3Cpath fill='%23fff' d='M0 0v2.47h18.934V0zm0 7.334V9.8h18.934V7.334zm0 7.409v2.469h18.934v-2.469z'/%3E%3C/svg%3E%0A") !important;
    }
  }
  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18.002' height='18.002' viewBox='0 0 18.002 18.002'%3E%3Cpath fill='%23fff' d='M15.14.108a.372.372 0 0 0-.525 0L9.263 5.46a.372.372 0 0 1-.525 0L3.387.108a.372.372 0 0 0-.525 0L.108 2.862a.372.372 0 0 0 0 .525L5.46 8.739a.372.372 0 0 1 0 .525L.108 14.615a.372.372 0 0 0 0 .525l2.754 2.754a.372.372 0 0 0 .525 0l5.352-5.352a.372.372 0 0 1 .525 0l5.352 5.352a.372.372 0 0 0 .525 0l2.754-2.754a.372.372 0 0 0 0-.525l-5.353-5.352a.372.372 0 0 1 0-.525l5.352-5.352a.372.372 0 0 0 0-.525z'/%3E%3C/svg%3E%0A") !important;
  }
  .back-arrow {
    border-radius: 5px;
    padding-bottom: 6px;
    padding-left: 5px;
    padding-right: 5px;
    margin-top: 7px;
    margin-right: 7px;
    &:hover {
      background-color: rgba(33, 47, 70, 0.5);
      padding-top: 0px;
      padding-bottom: 0px;
      padding-left: 5px;
      padding-right: 5px;
      border-radius: 5px;
    }
  }
  .alert-red-indicator {
    &::after {
      content: "";
      width: 8px;
      height: 8px;
      position: absolute;
      top: 0px;
      right: 0px;
      background: #eb4141;
      border-radius: 50%;
      display: inline-block;
    }
  }
  .alert-red-indicator-evc {
    &::after {
      content: "";
      width: 8px;
      height: 8px;
      position: absolute;
      top: -3px;
      right: -3px;
      background: #eb4141;
      border-radius: 50%;
      display: inline-block;
    }
  }
  .vertical-tank-red-indicator {
    &::after {
      content: "";
      width: 10px;
      height: 10px;
      position: absolute;
      top: -4px;
      right: 5px;
      background: #eb4141;
      border-radius: 50%;
      display: inline-block;
    }
  }
  .graph-mobile-device {
    ::-webkit-scrollbar {
      display: none;
    }
  }
  .csv-chart {
    padding: 15px 0px 15px;
    display: block;
    border-style: dashed;
    border-color: #b4d1ff;
    border-radius: 4px;
    border-width: 1px;
    .container p {
      padding-top: 10px;
      color: #666;
    }
  }
  .drag-drop-chart > div > div > div:nth-child(3) {
    border-radius: 50px !important;
}
  .drag-drop-chart {
    div {
      border-top: none !important;
      border-bottom: none !important;
      border-left: none !important;
      border-radius: 0px !important;
      align-items: normal !important;
      // flex-direction: inherit !important;
    }
  }
  .report-model {
    height: 500px;
    overflow: auto;
  }
  .removed-button {
    top: 22px !important;
  }
  .sample-csv-download{
    display: flex;
    padding: 10px;
    width: fit-content;
    color: #3f7cca;
    background-color: #f0f6ff;
    height: 40px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    cursor:"pointer";
  }
  .raw-rawRegister{
    text-align: center;
  outline: 1px solid #457bbf; 
  margin:0.5px;
  height: auto;
  width:auto;
  text-align: center;
  p{
    padding:10px 20px;
    text-align: center;
    vertical-align: middle;
    margin:0px !important
  }
  }

  .col-rawRegister{
    border-top: 1px solid #457bbf; 
  }
  .row-register-back-arrow{
    width: 40px;
    padding: 10px;
    background-color: #f0f6ff;
    cursor: pointer;
  }

  .tabcontentBlock {
    margin-top: 20px;
    margin-bottom: 60px;
  }
  .tabcontentBlock:last-child {
    margin-bottom: 10px;
  }
  .register-tank-vertical {
    width: 60px;
    height: 55px;
    // object-fit: cover
  }
  .register-tank-control-vertical {
    width: 45px;
    height: 55px;
  }
  .evc-tank-icon {
    width: 65px;
    height: 55px;
  }

  .row-register-table{
    td{
    padding:10px;
    border:1px solid #457bbf
  }
  }
  .tank-option-btn{
    font-size: 14px;
    color: #212f46;
    padding: 0.380rem 0.75rem;
    &:active {
      background-color: #f0f6ff !important;
    }
  }
  .settings-tabs {
    border-bottom: 1px solid #E2E2E2;

  }
  .settings {
    text-align: center;
    padding: 5px 10px;
    margin-right: 10px;
    height: 36px;
    border-bottom: 2px solid transparent;
    cursor: pointer;
    color: #808285;
    font-size: 14px;
    line-height: 24px;
    user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    &--active {
      border-bottom-color: #3F7CCA;
      color: #2c61a5;
    }
    &:hover {
      background: #f0f6ff;
    }
  }
  .chiller-setting-table{
    width: 50%;
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 5px;
    border: 1px solid #457BBF;
    margin-bottom: 0;
    &:not(:last-child) {
      margin-right: 30px;
    }
  }
  .chiller-setting-table-data {
    td { 
      border: 0;
    }
    &:not(:last-child) td {
      border-bottom: 1px solid #457BBF;
    }
  }
  .settings-accordion {
    background-color: transparent;
    &__item {
      border: 1px solid transparent !important;
      margin-bottom: 15px;
      .ant-collapse-header {
        border-radius: 4px;
        background-color: #F1F1F1;
        padding: 20px 40px 19px 30px;
        font-size: 16px;
      }
      &.ant-collapse-item-active {
        border-color: #3F7CCA !important;
        border-radius: 4px;
        margin-bottom: 30px;
        .ant-collapse-content-box {
          padding: 23px 20px 31px !important;
        }
        .ant-collapse-header {
          background-color: #F0F6FF;
          border-radius: 4px 4px 0px 0px;
        }
      }
    }
  }
  .device-icon--chiller {
    max-width: 42px;
    max-height: 60px;
    width: auto;
  }
  svg.fill-blue path{
    fill: #3ca1ff;
  }
  svg.fill-green path{
    fill: #7ae43f;
  }
  svg.fill-yellow path{
    fill: #efc53a;
  }
  svg.fill-purple path {
    fill: #b068d6;
  }

  svg.fill-red path{
    stroke: #ff0000;
  }
}

.alert_row_container{
  align-items: center;
  &_column{
    &__left{
      min-width: 15%;
      text-align: center;
    }
    &__right{
      max-width: 85%;
    }
  }
  &--icon{
    margin-top: .4rem;
    width: 33% !important;
  }
}

.status__icon--height{
  height: 1.56rem !important;
}

.mr_4{
  margin-right: .25rem !important;
}
.mr_8{
  margin-right: .8rem !important;
}

.chillerStateIcon{
  margin-left: 0.375rem !important ;
  margin-right: 0.125rem !important;
  height: 1.6rem !important;
}

.turckControlCard{
  &--bottom {
    bottom: 10px  !important;
  } 
  &--top {
    top: 5px  !important;
  }  
}

.turckGraphToolTipHeader{
  &--title{
    word-wrap: break-word;
  }
}