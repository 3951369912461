@media (min-width: 1200px) {
  .carousel {
    margin: auto !important;
  }
  .tank-page {
    .container {
      max-width: 850px;
    }
  }
  .row-full-width {
    .first-section {
      // margin-left: -20%;
      // flex: 0 0 -20%;
      min-width: 222px;
      max-width: max-content;
      padding-left: 20px;
      padding-right: 10px;
    }
    // .second-section {
    //   min-width: 100%;
    // }
    // .third-section {
    //   margin-right: -20%;
    //   flex: 0 0 -20%;
    //   min-width: 20%;
    // }
  }
}
@media (min-width: 1201px) and (max-width: 1250px) {
  .tank-page {
    .container {
      max-width: 850px;
    }
  }
  .row-full-width {
    // .first-section {
    //   margin-left: -23%;
    //   flex: 0 0 -20%;
    //   min-width: 23%;
    // }
    // .second-section {
    //   min-width: 100%;
    // }
    // .third-section {
    //   margin-right: -20%;
    //   flex: 0 0 -20%;
    //   min-width: 20%;
    // }
  }
}
@media (min-width: 1251px) and (max-width: 1300px) {
  .tank-page {
    .container {
      max-width: 860px;
    }
  }
  .row-full-width {
    // .first-section {
    //   margin-left: -20%;
    //   flex: 0 0 -20%;
    //   min-width: 20%;
    //   padding-left: 0px;
    // }
    // .second-section {
    //   min-width: 100%;
    // }
    // .third-section {
    //   margin-right: -20%;
    //   flex: 0 0 -20%;
    //   min-width: 20%;
    // }
  }
}

@media (min-width: 1301px) and (max-width: 1365px) {
  .tank-page {
    .container {
      max-width: 930px;
    }
  }
  .row-full-width {
    // .first-section {
    //   margin-left: -20%;
    //   flex: 0 0 -20%;
    //   min-width: 20%;
    //   padding-left: 19px;
    // }
    // .second-section {
    //   min-width: 100%;
    // }
    // .third-section {
    //   margin-right: -20%;
    //   flex: 0 0 -20%;
    //   min-width: 20%;
    // }
  }
}
@media (min-width: 1366px) and (max-width: 1399px) {
  .tank-page {
    .container {
      max-width: 960px;
    }
  }
  .row-full-width {
    // .first-section {
    //   margin-left: -21%;
    //   min-width: 20.75%;
    // }
    // .second-section {
    //   min-width: 100%;
    // }
    // .third-section {
    //   margin-right: -20%;
    //   min-width: 20%;
    // }
  }
}
@media (min-width: 1400px) {
  .tank-page {
    .container {
      max-width: 1000px;
    }
  }
  .row-full-width {
    // .first-section {
    //   margin-left: -20%;
    //   flex: 0 0 -20%;
    //   min-width: 20%;
    //   padding-left: 0px;
    // }
    // .second-section {
    //   min-width: 100%;
    // }
    // .third-section {
    //   margin-right: -20%;
    //   flex: 0 0 -20%;
    //   min-width: 20%;
    // }
  }
  .carousel {
    margin: auto !important;
  }
}

@media (max-width: 767px) {
  .btn-lg {
    line-height: 1.2rem;
  }
  .form-control-lg {
    height: calc(1.3em + 1rem + 2px);
    padding: 0.5rem 1rem;
  }
  .logo-top {
    margin: 30px 0px;
  }
  .mueller-logo {
    height: 40px;
  }
  .eye-icon {
    margin-top: -38px !important;
  }
  .sign-up-text {
    font-size: 12px;
  }
  .navbar-light .navbar-toggler {
    border: 0px;
    &:focus {
      outline: none;
    }
  }
  .navbar-light .navbar-toggler-icon {
    width: 19px;
    height: 18px;
  }
  .graph-tooltip {
    display: none;
  }
  .dygraph-hairline {
    display: none;
  }
  .dygraph-hairline div {
    display: none !important;
  }
  .system-item div {
    &::before {
      display: none !important;
    }
  }
  .manual-item div {
    &::before {
      display: block !important;
    }
  }
  }
@media (min-width: 768px) and (max-width: 1024px) {
  .btn-lg {
    line-height: 1.2rem;
  }
  .form-control-lg {
    height: calc(1.3em + 1rem + 2px);
    padding: 0.5rem 1rem;
  }
  .logo-top {
    margin: 30px 0px;
  }
  .mueller-logo {
    height: 40px;
  }
  .eye-icon {
    margin-top: -38px !important;
  }
  .sign-up-text {
    font-size: 12px;
  }
  .navbar-light .navbar-toggler {
    border: 0px;
    &:focus {
      outline: none;
    }
  }
  .navbar-light .navbar-toggler-icon {
    width: 19px;
    height: 18px;
  }
  .dygraph-hairline {
    display: block !important;
  }
  .dygraph-hairline div {
    display: block;
  }
  .system-item div {
    &::before {
      display: block !important;
    }
  }
  .manual-item div {
    &::before {
      display: block !important;
    }
  }
  .alert_row_container{
    &_column{
      &__left{
        min-width: 20%;
        text-align: center;
      }
      &__right{
        max-width: 80%;
      }
    } 
    &--icon{
      margin-top: .4rem;
      width: 28% !important;
    }
  }
}
